export const checkBackendStatus = async () => {
    try {
      if (process.env.REACT_APP_SKIP_DEMO_REGINSTER === "true") {
        return false;
      }
      const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";
      console.error("Trying URL:", API_URL);
      const response = await fetch(API_URL + "/health");
      console.error("Backend available:",response);
      if (response.ok) {
        return true;
      }
    } catch (error) {
      console.error("Backend not available:", error);
    }
    return false;
  };