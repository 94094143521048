// pages/BlogsList.js
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../Layout";
import styled from "styled-components";
import blogs from "../components/BlogsData";

const BlogsList = () => (
  <Layout>
    <PageContainer>
      <Header>
        <Title>Our Blogs</Title>
        <Subtitle>
          Discover articles, insights, and tips on how data and AI are reshaping
          decision-making. Stay informed and ahead of the curve with the latest
          in AI-driven solutions.
        </Subtitle>
      </Header>
      <BlogListContainer>
        {blogs.map((blog) => (
          <BlogCard key={blog.id} to={`/blogs/${blog.id}`}>
            <ImageContainer>
              <img
                src={`${process.env.PUBLIC_URL}/blogs/${blog.id}.webp`}
                alt={blog.title}
              />
            </ImageContainer>
            <BlogTitle>{blog.title}</BlogTitle>
            <MetaInfo>
              By {blog.author} • {blog.date} • {blog.readingTime}
            </MetaInfo>
          </BlogCard>
        ))}
      </BlogListContainer>
      <BlogsFooter>
        <FooterText>
          Some blog content and images on this site were generated with the
          assistance of ChatGPT, an AI model developed by OpenAI. All content
          has been reviewed and curated by our experts.
        </FooterText>
      </BlogsFooter>
    </PageContainer>
  </Layout>
);

export default BlogsList;

// Styled Components
const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const Title = styled.h3`
  margin: 16px 0;
  font-size: 2em;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`;

const Subtitle = styled.p`
  font-size: 1.1em;
  color: white;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const BlogListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
`;

const BlogCard = styled(Link)`
  text-decoration: none;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 180px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 150px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BlogTitle = styled.h3`
  margin: 16px;
  font-size: 1.3em;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`;

const MetaInfo = styled.p`
  margin: 0 16px 16px 16px;
  font-size: 0.9em;
  color: #888;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const BlogsFooter = styled.div`
  color: white;
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 0.8em;
  color: white;  

  @media (max-width: 768px) {
    font-size: 0.7em;
  }
`;