// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import { initializeApp, getApps } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYibsGHMFp5FQmWDdCsQRJoPDkExSMrlM",
  authDomain: "anyreportwebsite.firebaseapp.com",
  projectId: "anyreportwebsite",
  storageBucket: "anyreportwebsite.appspot.com",
  messagingSenderId: "275970789645",
  appId: "1:275970789645:web:5d8a89ffdd555bfe3ca044",
  measurementId: "G-5BF36E62QY"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export default app;

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}
const analytics = getAnalytics(app);
export { app, analytics };
