import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {Section} from "./SharedStyles"
const MarkdownPage = ({ file }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(file)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [file]);

  return <Section> <ReactMarkdown>{content}</ReactMarkdown> </Section>;
};

export default MarkdownPage;
