import React from "react";
import styled from "styled-components";

// Styled component for the email link
const ContactButton = styled.a`
  display: inline-block;
  background-color: #ffa500; /* Change to match your theme */
  color: white;
  padding: 10px 20px;
  font-size: 1em;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #ff8c00; /* Hover color */
  }
`;

export const ReachOutSection = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "30px" }}>
      <p>Have questions or want to learn more? Reach out to us!</p>
      <ContactButton href="mailto:info@any-report.com">
        Contact Us
      </ContactButton>
    </div>
  );
};

export const ContactButtonInfo = () => {
  return (
    <ContactButton href="mailto:info@any-report.com">Contact Us</ContactButton>
  );
};

export default ReachOutSection;
