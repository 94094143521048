// Layout.js
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from './breakpoints'; // Import breakpoints



const Layout = ({ children }) => (
  <AppContainer>
    <PageContent>{children}</PageContent>
  </AppContainer>
);

export default Layout;

const AppContainer = styled.div`
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #4d4e55; /* Light purple background */
  min-height: 100vh; /* Ensure full page height */
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 10px; /* Adjust padding for tablets */
    font-size: 14px; // Slightly smaller font for tablets
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 5px; /* Further adjust padding for mobile */
    font-size: 12px; // Slightly smaller font for tablets
  }
`;

const PageContent = styled.div`
  margin-top: 60px; /* Prevent navbar overlap */
  flex: 1; /* Ensure content takes available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 15px; /* Reduce padding on tablets */
    margin-top: 50px; /* Adjust margin to prevent overlap */
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 10px; /* Further reduce padding for mobile */
    margin-top: 40px; /* Adjust margin for smaller screens */
  }
`;
