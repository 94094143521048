import React, { useState } from "react";
import styled from "styled-components";
import { Container, DarkSection, Heading } from "./SharedStyles";
const ProductsSection = () => {
  const [activeTab, setActiveTab] = useState("feature1");

  const features = [
    {
      id: "feature1",
      title: "Transform Ideas into Formal Data Requirements",
      description:
        "Collaborate effortlessly with Oryn to brainstorm and craft comprehensive data requirements. Quickly define business questions, data sources, scope, and actionable plans in record time.",
      video: "/media/automated_data_collection.mp4",
      image: "/media/AnyReportSpec.png",
      name: "Requirements",
    },
    {
      id: "feature2",
      title: "Adaptive Data Modeling for Dynamic Needs",
      description:
        "Design flexible data models that evolve with your business. Build intuitive, scalable models that seamlessly align with your growth and changing requirements.",
      video: "/media/flexible_data_modeling.mp4",
      image: "/media/AnyReportDataModel.png",
      name: "Modeling",
    },
    {
      id: "feature3",
      title: "No-Code Database Creation in Minutes",
      description:
        "Instantly convert your data model into a fully operational database—without writing a single line of code. Empower your team to deploy and manage databases with ease.",
      video: "/media/flexible_data_modeling.mp4",
      image: "/media/AnyReportDataCollection.png",
      name: "No-Code DB",
    },
    {
      id: "feature4",
      title: "Automated Data Aggregation from Multiple Sources",
      description:
        "Streamline data collection with intelligent automation. Oryn consolidates data from various sources, reducing manual work and maintaining high data quality.",
      video: "/media/automated_data_collection.mp4",
      image: "/media/AnyReportDataCollection.png",
      name: "Data Collection",
    },
    {
      id: "feature5",
      title: "Actionable Insights for Data-Driven Decisions",
      description:
        "Generate insightful reports and analytics automatically. Focus on making strategic, data-driven decisions instead of spending time on data preparation.",
      video: "/media/insight_generation.mp4",
      image: "/media/AnyReportDataVisualization.png",
      name: "Insights",
    },
  ];

  return (
    <DarkSection id="products">
      <Container>
        <Heading>Unleash the Power of Automated Intelligence</Heading>
        <Tabs>
          {features.map((feature) => (
            <TabButton
              key={feature.id}
              $isActive={activeTab === feature.id}
              onClick={() => setActiveTab(feature.id)}
            >
              {feature.name}
            </TabButton>
          ))}
        </Tabs>
        <Content>
          {features.map((feature) =>
            activeTab === feature.id ? (
              <FeatureItem key={feature.id}>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
                {/* <FeatureImage src={feature.image} alt="Feature Image" /> */}
                {/* <video controls>
                  <source src={feature.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
              </FeatureItem>
            ) : null
          )}
        </Content>
      </Container>
    </DarkSection>
  );
};

export default ProductsSection;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  background: rgba(230, 210, 255, 0.6); /* Light translucent purple */
  border-radius: 30px;
  padding: 10px 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Lighter shadow */
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const TabButton = styled.button`
  position: relative;
  background: ${($isActive) =>
    $isActive
      ? "linear-gradient(135deg, rgba(173, 132, 255, 0.7), rgba(255, 240, 140, 0.7))"
      : "rgba(255, 255, 255, 0.2)"};
  backdrop-filter: blur(10px);
  border: ${(props) =>
    props.isActive
      ? "2px solid rgba(173, 132, 255, 0.7)"
      : "2px solid rgba(173, 132, 255, 0.3)"};
  color: ${(props) =>
    props.isActive ? "#000" : "#6d28d9"}; /* Dark purple for inactive text */
  border-radius: 50px;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: 0.1em;
  transition: all 0.4s ease;
  box-shadow: ${(props) =>
    props.isActive
      ? "0 0 20px rgba(255, 240, 140, 0.7), inset 0 0 15px rgba(173, 132, 255, 0.5)"
      : "0 4px 6px rgba(0, 0, 0, 0.1)"};

  &:hover {
    background: linear-gradient(
      135deg,
      rgba(255, 240, 140, 0.9),
      rgba(173, 132, 255, 0.9)
    );
    transform: translateY(-3px) scale(1.1);
    box-shadow: 0 0 25px rgba(255, 240, 140, 0.8),
      0 0 50px rgba(173, 132, 255, 0.8);
    color: #000; /* Ensure black text on hover */
  }

  &:active {
    transform: scale(0.98);
    box-shadow: none;
  }
  @media (max-width: 768px) {
    font-size: 0.5em;
    padding: 4px 4px;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  color: #444; /* Dark grey for better readability */
`;
const FeatureItem = styled.div`
  animation: fadeIn 0.5s ease-in-out;

  h3 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #ffc107; /* Darker yellow-gold for visibility */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle shadow for better contrast */

    @media (max-width: 768px) {
      font-size: 1.5em;
    }
  }

  p {
    font-size: 1.5em;
    margin-bottom: 20px;
    line-height: 1.6;
    color: white; /* Dark grey for readability */

    @media (max-width: 768px) {
      font-size: 1.1em;
    }
  }

  video {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
`;
