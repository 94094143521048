// ContactSection.js
import React from "react";
import styled from "styled-components";
import BookDemoModal from "./BookDemoModal";
import {
  DarkSection,
  Container,
  Heading,
  SubHeading,
  ActionButton,
} from "./SharedStyles";

const ContactSection = ({
  openModal,
  isModalOpen,
  closeModal,
  registed_demo,
}) => {
  return (
    <DarkSection id="contact">
      <Container>
        <Heading>Contact Us Today</Heading>
        <SubHeading>
          Experience how AnyReport can streamline your data workflows.
        </SubHeading>
        <ButtonContainer>
          {/* <ActionButton $primary>Create Free</ActionButton> */}
          <ActionButton $primary onClick={openModal} disabled={!registed_demo}>
            {!registed_demo ? "Book a Demo (Coming Soon...)" : "Book a Demo"}
          </ActionButton>
        </ButtonContainer>
        <BookDemoModal isOpen={isModalOpen} onClose={closeModal} />
      </Container>
    </DarkSection>
  );
};

export default ContactSection;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;
