
const blogs = [
    {
      id: "blog1",
      title:
        "The Rise of AI Data Experts: Democratizing Decision-Making in the GenAI Era",
      author: "AnyReport",
      date: "October 18, 2024",
      readingTime: "5 min read",
      image: "https://via.placeholder.com/800x400?text=Blog+1+Image",
    },
    {
      id: "blog2",
      title: "Why Wait? Let AI Unlock Insights in Real-Time with Oryn",
      author: "AnyReport",
      date: "October 20, 2024",
      readingTime: "4 min read",
      image: "https://via.placeholder.com/800x400?text=Blog+2+Image",
    },
    {
      id: "blog3",
      title:
        "From Concept to Insight: How AI is Automating the Data Workflow Lifecycle",
      author: "AnyReport",
      date: "October 22, 2024",
      readingTime: "6 min read",
      image: "https://via.placeholder.com/800x400?text=Blog+3+Image",
    },
    {
      id: "blog4",
      title:
        "Redefining Data Roles: Embracing Change in the Age of Automated Analytics",
      author: "AnyReport",
      date: "October 24, 2024",
      readingTime: "7 min read",
      image: "https://via.placeholder.com/800x400?text=Blog+4+Image",
    },
  ];
  export default blogs;
