import React from "react";
import styled from "styled-components";
import { StyledLink } from "./SharedStyles";

const Footer = () => {
  return (
    <FooterContainer>
      <LinksContainer>
        <StyledLink to="/privacy-policy" target="_blank">
          Privacy Policy
        </StyledLink>
        <StyledLink to="/terms-of-service" target="_blank">
          Terms of Service
        </StyledLink>
        <StyledLink to="/copyright-notice" target="_blank">
          Copyright Notice
        </StyledLink>
        <StyledLink to="/disclaimer" target="_blank">
          Disclaimer
        </StyledLink>
        <StyledLink to="/cookie-policy" target="_blank">
          Cookie Policy
        </StyledLink>
      </LinksContainer>
      <TopSection>
        <Logo
          src={`${process.env.PUBLIC_URL}/AnyReportWhiteCropped.jpg`}
          alt="AnyReport Logo"
        />
        <Copyright>© AnyReport 2024. All Rights Reserved.</Copyright>
      </TopSection>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #4d4e55;
  padding: 20px;
  color: white;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const Logo = styled.img`
  width: 80px;
  height: auto;

  @media (max-width: 768px) {
    width: 60px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 15px 0 0 0 ;

  @media (max-width: 768px) {
    justify-content: flex-start;
    & > * {
      flex-basis: calc(50% - 10px); // Two items per row with gap
    }
  }
`;

const Copyright = styled.div`
  font-size: 1em;
  color: white;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;
