import styled from "styled-components";
import { HashLink as RouterLink } from "react-router-hash-link";

export const CustomSpan = styled.span`
  color: ${(props) =>
    props.color || "#FFC107"}; /* Default to gold if no color is provided */
  font-weight: bold;
`;

// Styled React Router Link with the same styles as NavLink
export const StyledLink = styled(RouterLink)`
  text-decoration: none;
  color: ${(props) => props.color || "white"};
  font-size: ${(props) => props.fontSize || "16px"};
  white-space: nowrap;
  font-weight: ${(props) => props.fontWeight || "normal"};

  &:hover {
    color: #61dafb;
  }

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 5px 0;
  }
`;

export const Section = styled.section`
  padding: 80px 20px;
  background-color: #f0f4fc; /* Light pastel blue */
  color: #000;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

export const DarkSection = styled.section`
  padding: 80px 20px;
  background-color: #4d4e55;
  color: #000;
  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  text-align: center;
  background: linear-gradient(135deg, #6f42c1, #8e44ad);
  color: #fff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */

  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 15px;
  }
`;

export const Heading = styled.h2`
  font-size: 2.4em;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`;

export const SubHeading = styled.p`
  margin-bottom: 20px;
  font-size: 2em;
  color: #ffc107;
  opacity: 0.8;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const AnimatedSubtitle = styled.div`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 2em;
  font-weight: 500;
  color: #ffc107;
  min-height: 100px; /* Fixed height to prevent content shifting */
  overflow: hidden; /* Hide any overflowing content */

  @media (max-width: 768px) {
    font-size: 1.5em;
    min-height: 60px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const ActionButton = styled.button`
  padding: 12px 24px; /* Larger padding for a more prominent button */
  border: none;
  margin: 20px auto;
  border-radius: 8px; /* Slightly larger border-radius for a smoother look */
  font-size: 1.2em; /* Reduced font size slightly for better proportion */
  font-weight: 600; /* Make the text a bit bolder */
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-transform: uppercase; /* Makes the button text more engaging */
  letter-spacing: 0.05em; /* Adds some space between letters for readability */
  box-shadow: 0 4px 15px rgba(255, 193, 7, 0.2); /* Subtle shadow for depth with a gold tone */

  /* Complementary theme */
  background-color: ${({ $primary }) =>
    $primary ? "#FFC107" : "#fff"}; /* Gold background */
  color: ${({ $primary }) =>
    $primary ? "#6A1B9A" : "#FFC107"}; /* Deep purple text on gold background */
  border: ${({ $primary }) =>
    $primary
      ? "none"
      : "2px solid #FFC107"}; /* Gold border for secondary button */

  &:hover {
    background-color: ${({ $primary }) =>
      $primary ? "#FFA000" : "#FFC107"}; /* Darker gold on hover */
    color: #fff;
    box-shadow: 0 6px 20px rgba(255, 193, 7, 0.3); /* Enhance shadow on hover */
    transform: translateY(-3px); /* Slight lift effect on hover */
  }

  &:active {
    transform: translateY(0); /* Reset the lift effect on click */
    box-shadow: 0 3px 10px rgba(255, 193, 7, 0.2); /* Reduce shadow on click */
  }

  &:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.4); /* Custom focus outline with gold */
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 1em;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%; /* Full width */
  padding: 0; /* Remove padding between sections */
  margin: 0; /* No margin between sections */
  background-color: #f3e8ff; /* Same light purple background */
`;
