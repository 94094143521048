// App.js
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import BlogsList from "./pages/BlogsList"; // Import Blogs List
import BlogArticle from "./pages/BlogArticle"; // Import Blogs List
import Home from "./pages/Home"; // Main Home Page
import MarkdownPage from './components/MarkdownPage';  // Markdown renderer component
import Layout from "./Layout";
import Footer from "./components/Footer";
/**
 * The main App component, which wraps the entire application.
 *
 * It includes the Navbar at the top of the page, a ScrollToTop component
 * that ensures the user is scrolled to the top of the page when navigating
 * between pages, and a set of routes that determine which page to display
 * based on the current URL.
 *
 * The App component also manages the state of the "Book a Demo" modal, which
 * is displayed when the user clicks on the "Book a Demo" button in the
 * Navbar. The modal is passed as a prop to the Home component, which is
 * responsible for displaying the modal.
 */
function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  return (
    <>
    <Layout>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              isModalOpen={isModalOpen}
              openModal={handleModalOpen}
              closeModal={handleModalClose}
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={<MarkdownPage file="/privacy-policy.md" />}
        />
        <Route
          path="/terms-of-service"
          element={<MarkdownPage file="/terms-of-service.md" />}
        />
        <Route
          path="/copyright-notice"
          element={<MarkdownPage file="/copyright-notice.md" />}
        />
        <Route
          path="/disclaimer"
          element={<MarkdownPage file="/disclaimer.md" />}
        />
        <Route
          path="/cookie-policy"
          element={<MarkdownPage file="/cookie-policy.md" />}
        />
        <Route path="/blogs" element={<BlogsList />} />
        <Route path="/blogs/:id" element={<BlogArticle />} />
      </Routes>
      <Footer />
      </Layout>
    </>
  );
}

export default App;
