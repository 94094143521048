// BlogArticle.js
import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaTwitter,
  FaPinterest,
  FaEnvelope,
  FaLinkedin,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import "highlight.js/styles/github.css";
import styled from "styled-components";
import Layout from "../Layout";
import blogs from "../components/BlogsData"; // Import shared metadata
import Markdown from "react-markdown";

const BlogArticle = () => {
  const { id } = useParams();
  const [content, setContent] = useState("");

  const blog = blogs.find((blog) => blog.id === id);
  const blogUrl = `${window.location.origin}/blogs/${id}`;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: blog.title,
    author: {
      "@type": "Person",
      name: blog.author,
    },
    datePublished: blog.date,
    image: `${process.env.PUBLIC_URL}/blogs/${id}.webp`,
    articleBody: content,
    publisher: {
      "@type": "Organization",
      name: "AnyReport",
      logo: {
        "@type": "ImageObject",
        url: `${process.env.PUBLIC_URL}/logo_anyreport.jpeg`,
      },
    },
    url: blogUrl,
    description: "Discover insights and trends on AI-powered workflows.",
  };

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/blogs/${id}.md`)
      .then((response) => (response.ok ? response.text() : Promise.reject()))
      .then((data) => {
        const lines = data.split("\n");
        if (lines[0].startsWith("# ")) lines.shift();
        setContent(lines.join("\n"));
      })
      .catch(() => setContent("Blog not found."));
  }, [id]);

  if (!blog) return <div>Blog not found.</div>;

  const shareOnFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${blogUrl}`,
      "_blank"
    );
  };

  const shareOnTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${blogUrl}&text=${encodeURIComponent(
        blog.title
      )}`,
      "_blank"
    );
  };

  const shareOnLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${blogUrl}`,
      "_blank"
    );
  };

  const shareOnPinterest = () => {
    window.open(
      `https://pinterest.com/pin/create/button/?url=${blogUrl}`,
      "_blank"
    );
  };

  const sendEmail = () => {
    window.open(
      `mailto:?subject=${encodeURIComponent(
        "Check out this blog: " + blog.title
      )}&body=I thought you might enjoy this blog: ${blogUrl}`,
      "_blank"
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>{blog.title} - AnyReport</title>
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content="Discover insights and trends on AI-powered workflows."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/blogs/${id}.webp`}
        />
        <meta property="og:url" content={blogUrl} />
        <meta property="og:type" content="article" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta
          name="twitter:description"
          content="Discover insights and trends on AI-powered workflows."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/blogs/${id}.webp`}
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <ArtPageContainer>
        <SocialShareBar>
          <SocialIcon onClick={shareOnFacebook}>
            <FaFacebook />
          </SocialIcon>
          <SocialIcon onClick={shareOnTwitter}>
            <FaTwitter />
          </SocialIcon>
          <SocialIcon onClick={shareOnLinkedIn}>
            <FaLinkedin />
          </SocialIcon>
          <SocialIcon onClick={shareOnPinterest}>
            <FaPinterest />
          </SocialIcon>
          <SocialIcon onClick={sendEmail}>
            <FaEnvelope />
          </SocialIcon>
        </SocialShareBar>
        <ContentContainer>
          <ImageContainer>
            <img
              src={`${process.env.PUBLIC_URL}/blogs/${id}.webp`}
              alt={blog.title}
              loading="lazy"
            />
          </ImageContainer>
          <ArticleHeader>
            <h1>{blog.title}</h1>
            <AuthorInfo>
              By {blog.author} • {blog.readingTime} • {blog.date}
            </AuthorInfo>
          </ArticleHeader>
          <MarkdownContainer>
            <ReactMarkdown
              children={content}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw, rehypeHighlight]}
            />
          </MarkdownContainer>
        </ContentContainer>
      </ArtPageContainer>
    </Layout>
  );
};

export default BlogArticle;

// Styled Components

// Styled Components
const MarkdownContainer = styled.div`
  img {
    max-width: 80%;
    height: auto;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-x: auto;
  }

  code {
    word-break: break-all;
    white-space: pre-wrap;
  }

  table {
    max-width: 80%;
    overflow-x: auto;
    display: block;
  }
`;

const ArtPageContainer = styled.div`
  padding: 0px;
  background-color: #f5f5f5;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 0px;
    max-width: 500px;
  }
`;

const ContentContainer = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 10px;
    width: 80%;
    max-width: 600px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    height: 200px;
    width: 100%;
  }
`;

const ArticleHeader = styled.div`
  margin-bottom: 20px;
  h1 {
    font-size: 22px;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      font-size: 36px;
    }
  }
`;

const AuthorInfo = styled.p`
  font-size: 14px;
  color: #888;
`;

const SocialShareBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

const SocialIcon = styled.div`
  margin: 0 10px;
  font-size: 24px;
  cursor: pointer;
  color: #555;
  transition: color 0.3s ease;

  &:hover {
    color: #000;
  }
`;
