import React, { useState } from "react";

import styled from "styled-components";

const BookDemoModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    role: "",
    industry: "",
  });
  const [statusMessage, setStatusMessage] = useState(null); // Status message state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";
      const response = await fetch(`${API_URL}/api/demo-requests`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        showStatusMessage("Request submitted successfully!", "success");
        onClose(); // Close the modal
      } else {
        const errorData = await response.json();
        const message = errorData.message || "Failed to submit request.";
        showStatusMessage(message, "error");
      }
    } catch (error) {
      console.error(error);
      showStatusMessage("An error occurred. Please try again.", "error");
    }
  };

  const showStatusMessage = (message, type) => {
    setStatusMessage({ message, type });

    // Automatically hide the message after 3 seconds
    setTimeout(() => {
      setStatusMessage(null);
    }, 3000);
  };

  if (!isOpen) return null; // Do not render if modal is closed

  return (
    <>
      <Overlay onClick={onClose} />
      <ModalContent role="dialog">
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2>Book a Demo</h2>
        <p>
          Please share your contact information and our team will get in touch.
        </p>
        <Form onSubmit={handleSubmit}>
          <FormRow>
            <Label htmlFor="firstName">First Name</Label>
            <Input
              type="text"
              name="first_name"
              placeholder="First Name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Label htmlFor="company">Company</Label>
            <Input
              type="text"
              name="company"
              placeholder="Company"
              value={formData.company}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <Label htmlFor="role">Your Role</Label>
            <Select
              id="role"
              name="role"
              value={formData.role}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Select Your Role
              </option>
              <option value="Business Analyst">Business Analyst</option>
              <option value="Data Scientist">Data Scientist</option>
              <option value="Data Engineer">Data Engineer</option>
              <option value="Software Engineer">Software Engineer</option>
              <option value="Product Manager">Product Manager</option>
              <option value="Technical Consultant">Technical Consultant</option>
              <option value="Project Manager">Project Manager</option>
              <option value="Chief Technology Officer">
                Chief Technology Officer (CTO)
              </option>
              <option value="Chief Data Officer">
                Chief Data Officer (CDO)
              </option>
              <option value="Chief Executive Officer">
                Chief Executive Officer (CEO)
              </option>
              <option value="UX/UI Designer">UX/UI Designer</option>
              <option value="QA Engineer">QA Engineer</option>
              <option value="Operations Manager">Operations Manager</option>
              <option value="Other">Other</option>
            </Select>
          </FormRow>
          <FormRow>
            <Label htmlFor="industry">Industry</Label>
            <Select
              id="industry"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
            >
              <option value="" disabled selected hidden>
                Select Industry
              </option>
              <option value="Finance">Finance</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Technology">Technology</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Retail">Retail</option>
              <option value="Education">Education</option>
              <option value="Logistics">Logistics</option>
              <option value="Energy">Energy</option>
              <option value="Government">Government</option>
              <option value="Government">Defence</option>
              <option value="Telecommunications">Telecommunications</option>
              <option value="Other">Other</option>
            </Select>
          </FormRow>
          <SubmitButton type="submit">Send</SubmitButton>
        </Form>
        {statusMessage && (
          <StatusMessage type={statusMessage.type}>
            {statusMessage.message}
          </StatusMessage>
        )}
      </ModalContent>
    </>
  );
};

export default BookDemoModal;

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
  z-index: 999;
`;

const ModalContent = styled.div`
  position: fixed;
  role: "dialog";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 400px;
  max-width: 90%; /* Ensure it fits smaller screens */
  z-index: 1000;

  h2 {
    margin-top: 0;
    color: #6a1b9a;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
  }

  p {
    margin-bottom: 20px;
    color: #555;
    font-size: 1.1em;
    text-align: center;
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr; /* Fixed width for labels */
  align-items: center;
  gap: 10px; /* Spacing between label and input */
`;

const Label = styled.label`
  color: black;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left; /* Align label text to the right */
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  width: 100%; /* Ensure the input takes full width */
  box-sizing: border-box;
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  width: 100%; /* Ensure the select takes full width */
  box-sizing: border-box;
  background-color: white;
`;

const SubmitButton = styled.button`
  padding: 10px;
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;

  &:hover {
    background-color: #0056b3;
  }
`;

const StatusMessage = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: ${(props) =>
    props.type === "success" ? "#d4edda" : "#f8d7da"};
  color: ${(props) => (props.type === "success" ? "#155724" : "#721c24")};
  border: 1px solid
    ${(props) => (props.type === "success" ? "#c3e6cb" : "#f5c6cb")};
  border-radius: 5px;
  text-align: center;
`;
