// About.js
import React from "react";
import styled from "styled-components";
import { DarkSection, Container, SubHeading, Heading } from "./SharedStyles";

const AboutSection = () => {
  return (
    <DarkSection id="about">
      <Container>
        <Heading>About Us</Heading>
        <SubHeading>
          Redefining data workflows to make smarter decisions easier and faster.
        </SubHeading>
        <Paragraph>
          At AnyReport, we believe in empowering organizations with insights
          they can trust. Our goal is to remove the complexity from data
          processes, helping businesses unlock the true potential of their data.
        </Paragraph>
        <Paragraph>
          Although we're still in stealth mode, our team brings decades of
          experience in software engineering, data science, and business
          strategy. We're working hard behind the scenes to create a solution
          that will transform how companies work with data.
        </Paragraph>
        <Paragraph>
          Stay tuned for what's coming next – we're excited to share it with you
          soon! If you're interested in learning more, feel free to get in touch
          with us or book a demo.
        </Paragraph>
      </Container>
    </DarkSection>
  );
};

export default AboutSection;

const Paragraph = styled.p`
  font-size: 1.2em;
  color: white;
  opacity: 0.85;
  line-height: 1.6;
  margin-bottom: 20px;
`;
