// page/Home.js
import React, { useEffect, useState } from "react";
import { checkBackendStatus } from "../api/dal";
import ProductsSection from "../components/ProductsSection";
import styled from "styled-components";
import ContactSection from "../components/ContactSection";
// import FAQSection from "../components/FAQSection";
import AboutSection from "../components/AboutSection";
import BookDemoModal from "../components/BookDemoModal";
import ReachOutSection from "../components/SendUsEmail";
import { ReactTyped } from "react-typed";
import { Helmet } from 'react-helmet';

import {
  CustomSpan,
  AnimatedSubtitle,
  ButtonContainer,
  ActionButton,
  SectionWrapper,
} from "../components/SharedStyles";


const Home = ({ isModalOpen, openModal, closeModal }) => {
  const [backendAvailable, setBackendStatus] = useState(false);

  // Check if backend is reachable by calling a simple health endpoint
  useEffect(() => {
    const updateBackendStatus = async () => {
      try {
        const res = await checkBackendStatus();
        setBackendStatus(res);
      } catch (error) {
        console.error("Backend not available:", error);
        setBackendStatus(false);
      }
    };

    updateBackendStatus();
  }, []); // Run only on component mount

  return (
    <>
      <Helmet>
        <title>
          AnyReport - AI-Powered Insights for Better Decision Making
        </title>
        <meta
          name="description"
          content="Discover how AnyReport's AI-powered analytics can transform your business decision-making process."
        />
      </Helmet>
      <HeroSection id="home">
        <HeroContent>
          <HeroTitle id="home-title">
            Craft <CustomSpan color="#FFC107">AnyReport</CustomSpan>. <br />
            <CustomSpan color="#1E90FF">Fast</CustomSpan>.{" "}
            <CustomSpan color="#20C997">Simple</CustomSpan>.
          </HeroTitle>
          <p>
            Our AI Data Expert, Oryn, helps humans collect, organize, and
            analyze information efficiently. Unlock the power of decision-making
            through an automated data workflow.
          </p>
          <AnimatedSubtitle>
            <ReactTyped
              strings={[
                "Brainstorm with Oryn about the data you need.",
                "Stay in control as Oryn shapes the structure for your data.",
                "Guide Oryn as she gathers the right data for you.",
                "Turn your data into insights with charts and easy-to-read statistics.",
              ]}
              typeSpeed={50}
              backSpeed={10}
              loop
            />
          </AnimatedSubtitle>
          <ButtonContainer>
            <ActionButton
              $primary
              onClick={openModal}
              disabled={!backendAvailable}
            >
              {!backendAvailable
                ? "Book a Demo (Coming Soon...)"
                : "Book a Demo"}
            </ActionButton>
          </ButtonContainer>
          <BookDemoModal isOpen={isModalOpen} onClose={closeModal} />
          <ReachOutSection />
        </HeroContent>
      </HeroSection>
      <SectionWrapper>
        <ProductsSection />
        {/* <FAQSection /> */}
        <AboutSection />
        <ContactSection
          openModal={openModal}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          registed_demo={backendAvailable}
        />
      </SectionWrapper>
    </>
  );
};

export default Home;

// Styled Components
const HeroContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  p {
    font-size: 1.4em;
    margin-bottom: 10px;
    line-height: 1.4;
  }
`;

const HeroTitle = styled.h1`
  text-align: center;
  font-size: 2.2em;
  margin-bottom: 8px;
  line-height: 1.3;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #6f42c1, #8e44ad);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0; /* Remove extra padding */
  min-height: 50vh;
  width: 100%; /* Full width */
  margin: 0; /* No margin */
`;
