// Navbar.js
import React from "react"; // Ensure this is present
// import { HashLink as RouterLink } from "react-router-hash-link";
import styled from "styled-components";
import { StyledLink } from "./SharedStyles";
import { ContactButtonInfo } from "./SendUsEmail";

const Navbar = () => {
  return (
    <NavContainer>
      <LogoContainer>
        <StyledLink to="/">AnyReport</StyledLink>
      </LogoContainer>
      <CenterNavLinks>
        <StyledLink smooth to="/#home">
          Home
        </StyledLink>
        <StyledLink smooth to="/#products">
          Products
        </StyledLink>
        {/* <StyledLink smooth to="/#faq">
          FAQ
        </StyledLink> */}
        <StyledLink smooth to="/#about">
          About
        </StyledLink>
        <StyledLink to="/blogs">Blogs</StyledLink>
        <StyledLink smooth to="/#contact" color="#FFC107" fontWeight="bold">
          Request Demo
        </StyledLink>
      </CenterNavLinks>
      <RightNavLinks>
        <ContactButtonInfo />
      </RightNavLinks>
    </NavContainer>
  );
};

// Styled Components

const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #4d4e55;
  color: white;
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CenterNavLinks = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap; /* Ensure links wrap on smaller screens */

  @media (max-width: 768px) {
    justify-content: center;
    font-size: 10px;
    gap: 10px;
  }
`;

const RightNavLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    font-size: 10px;
    gap: 10px;
  }
`;

export default Navbar;
